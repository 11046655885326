<template>
  <div style="padding: 10px">
    <van-form @submit="onSubmit" :label-width="60" :show-error="false">
      <van-field readonly disabled name="carNumber" label="车牌号" :placeholder="carNumber" />
      <van-field readonly name="scan" label="扫描人数">
        <template #input>
          <span>{{ tourists.length + Number(code_number) }}人</span>
        </template>
        <template #button>
          <van-icon name="scan" size="60" @click="scanAction" />
        </template>
      </van-field>
      <van-field v-model="amount" name="amount" type="digit" label="总人数" placeholder="填写总人数" @input="amountChange"
        :rules="[{ required: true, message: '请填写总人数' }]" />
      <van-field v-model="invalid" name="invalid" type="digit" label="手输无效" placeholder="填写手输无效人数" @input="invalidChange"
        :rules="[{ required: true, message: '请填写手输无效人数' }]" />
      <van-field v-model="effective" v-if="domianId !== 'DOMAIN-9'" name="effective" label="手输有效" placeholder="填写手输有效人数"
        disabled />
      <div style="margin: 20px">
        <van-button round block type="info" native-type="submit">
          直接上传
        </van-button>
      </div>
    </van-form>
    <div style="margin: 16px">
      <van-button :loading="nativeLoading" round block @click="save">
        存入本地
      </van-button>
    </div>

    <audio src="../assets/readok.mp3" id="eventAudio"></audio>
    <!-- <audio src="../assets/readok.mp3" autoplay loop></audio> -->

    <van-overlay :show="loading" bind:click="onClickHide">
      <van-loading type="spinner" color="#FFFFFF" style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        " />
    </van-overlay>
  </div>
</template>

<script>
import { Form, Field, Icon, Button, Toast, Dialog, Overlay, Loading } from "vant";

// import Vconsole from "vconsole";
// new Vconsole();

var _ = require("lodash");

// import moment from 'moment';

import wx from "weixin-js-sdk";

export default {
  components: {
    vanForm: Form,
    vanField: Field,
    vanIcon: Icon,
    vanButton: Button,
    vanOverlay: Overlay,
    vanLoading: Loading
  },
  data() {
    return {
      carNumber: null,
      regTime: null,
      domianId: this.$store.getters.USER.domain,
      amount: null,
      invalid: null,
      effective: null,

      code_number: null,

      tourists: [],

      nativeLoading: false,

      loading: false,

      users: []
    };
  },
  mounted() {
    this.carNumber = this.$route.params.car_number;
    this.regTime = this.$route.params.registration_time;

    if (this.$route.params.invalid_number_artificial) {
      this.invalid = this.$route.params.invalid_number_artificial;
    }
    if (this.$route.params.effective_number_artificial) {
      this.effective = this.$route.params.effective_number_artificial;
    }
    if (this.$route.params.code_number) {
      this.code_number = this.$route.params.code_number;
    }
    if (this.$route.params.sign_up_ids) {
      this.tourists = this.$route.params.sign_up_ids;

      if (this.$store.getters.USER.domain === "DOMAIN-9") {
        this.amount = Number(this.invalid) + Number(this.effective);
      } else {
        this.amount = this.tourists.length + Number(this.invalid) + Number(this.effective) + Number(this.code_number);
      }
    }
    if (this.$route.params.users) {
      this.users = this.$route.params.users;
    }

    this.http.post(
      this.ports.user.wxSignature,
      {
        code: process.env.VUE_APP_WX_CODE,
        url: window.location.href.split("#")[0]
      },
      res => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          jsApiList: ["checkJsApi", "scanQRCode"] // 必填，需要使用的JS接口列表
        });
        wx.error(function (res) {
          // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
          console.log("配置验证失败: " + res.errMsg);
        });

        // wx.ready(function () {
        //     // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
        //     // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
        //     wx.checkJsApi({ // 判断当前客户端版本是否支持指定JS接口
        //         jsApiList: [
        //             'scanQRCode'
        //         ],
        //         success: function (res) { // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
        //             if (res.checkResult.scanQRCode === true) {
        //                 console.log('配置成功')
        //             } else {
        //                 alert('抱歉，当前客户端版本不支持扫一扫')
        //             }
        //         },
        //         fail: function (res) { // 检测getNetworkType该功能失败时处理
        //             console.log('fail' + res)
        //         }
        //     })
        // })
      },
      err => {
        console.log(err);
      }
    );
  },
  methods: {
    numberFormat(string) {
      if (string) {
        return string;
      } else {
        return "0";
      }
    },
    onSubmit() {
      Dialog.confirm({
        title: "提示",
        message: "确认上传吗？"
      })
        .then(() => {
          // on confirm
          let car = {
            plate_number: this.carNumber,
            registration_time: this.regTime,
            effective_number_artificial: this.numberFormat(this.effective),
            invalid_number_artificial: this.numberFormat(this.invalid),
            code_number: this.numberFormat(this.code_number),
            sign_up_ids: this.tourists
          };
          this.$store.dispatch("EDIT_CAR", car);
          // this.$store.dispatch('DELETE_CAR', car)

          this.http.post(
            this.ports.business.dataUpload(this.$store.getters.USER.domain),
            {
              bus_list: [car]
            },
            res => {
              console.log(res);
              Toast.success("已上传");
              this.$router.replace({
                path: "/"
              });
            },
            err => {
              console.log(err);
              Toast.fail("上传失败");
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    save() {
      if (this.carNumber) {
        this.nativeLoading = true;
        let car = {
          plate_number: this.carNumber,
          registration_time: this.regTime,
          effective_number_artificial: this.numberFormat(this.effective),
          invalid_number_artificial: this.numberFormat(this.invalid),
          code_number: this.numberFormat(this.code_number),
          sign_up_ids: this.tourists,
          users: this.users
        };
        this.$store.dispatch("EDIT_CAR", car);

        this.nativeLoading = false;
        this.$router.replace({
          path: "/"
        });
      } else {
        Toast.fail("请输入车牌号码");
      }
    },
    invalidChange(value) {
      if (this.$store.getters.USER.domain === "DOMAIN-9") {
        this.effective = this.amount - value;
      } else {
        this.effective = this.amount - this.tourists.length - this.code_number - value;
      }
    },
    amountChange(value) {
      if (this.$store.getters.USER.domain === "DOMAIN-9") {
        this.effective = value - this.invalid;
      } else {
        this.effective = value - this.tourists.length - this.invalid - this.code_number;
      }
    },

    scanAction() {
      if (this.carNumber) {
        let self = this;

        wx.ready(function () {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
              console.log("code:" + result);

              // 提示音
              var ua = navigator.userAgent.toLowerCase();
              if (/android/.test(ua)) {
                let buttonAudio = document.getElementById("eventAudio");
                buttonAudio.play();
              }

              self.handleQRCode(result);
            }
          });
        });
      } else {
        Toast.fail("请先输入车牌号码");
      }
    },

    handleQRCode(result) {
      let string = result;

      if (_.startsWith(string, "PGC_CCQ_")) {
        let stringArr = _.split(string, "#", 5);
        // let now = moment(new Date()).format('YYYY-MM-DD');
        // let dateFromCode = stringArr[1];
        // if (now == dateFromCode) {

        if (Date.now() - stringArr[4] > 120000) {
          Toast.fail("二维码已过期");
        } else {

          let useCode = stringArr[3];

          let code = _.replace(stringArr[0], "PGC_CCQ_", "");

          let indexCode = this.tourists.indexOf(code);
          let indexUser = this.users.indexOf(useCode);

          if (stringArr[2] !== this.$store.getters.USER.domain) {
            Toast.fail("不在当前领域");
          } else if (indexCode != -1 || indexUser != -1) {
            Toast.fail("已存在");
          } else {
            this.tourists.push(code);
            this.users.push(useCode);

            if (this.$store.getters.USER.domain != "DOMAIN-9") {
              this.amount = this.tourists.length + Number(this.invalid) + Number(this.effective) + Number(this.code_number);
            }

            let car = {
              plate_number: this.carNumber,
              registration_time: this.regTime
              // effective_number_artificial: null,
              // invalid_number_artificial: null,
              // sign_up_ids: [],
            };
            let data = {
              car: car,
              person: code,
              user: useCode
            };

            this.$store.dispatch("ADD_PERSON", data);
          }

          let self = this;
          setTimeout(function () {
            self.scanAction();
          }, 1000);
          // } else {
          //     Toast.fail('非当日车票，无法核销');
          // }

        }
      } else if (string.length == 6) {
        //判断佟二堡
        if (this.$store.getters.USER.domain === "DOMAIN-9") {
          Toast.fail("请出示乘车码");
        } else {
          let indexUser = this.users.indexOf(string);
          if (indexUser != -1) {
            Toast.fail("已存在");
          } else {
            this.loading = true;
            // let now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
            this.http.post(
              this.ports.business.codeUpload(this.$store.getters.USER.domain),
              {
                replace_artificial: true,
                bus_list: [
                  {
                    plate_number: this.carNumber,
                    registration_time: this.regTime,
                    vip_member_code: string
                  }
                ]
              },
              res => {
                console.log(res);
                Toast.success("已验证上传");

                this.users.push(string);

                this.code_number = Number(this.code_number) + 1;
                if (this.$store.getters.USER.domain != "DOMAIN-9") {
                  this.amount = this.tourists.length + Number(this.invalid) + Number(this.effective) + Number(this.code_number);
                }

                let car = {
                  plate_number: this.carNumber,
                  registration_time: this.regTime
                  // effective_number_artificial: null,
                  // invalid_number_artificial: null,
                  // sign_up_ids: [],
                };
                let data = {
                  car: car,
                  user: string
                };

                this.$store.dispatch("ADD_CODE", data);

                this.loading = false;

                let self = this;
                setTimeout(function () {
                  self.scanAction();
                }, 1000);
              },
              err => {
                console.log(err);
                if (err.message == "RESOURCE_NOT_EXIST") {
                  Toast.fail("会员不存在");
                } else if (err.message == "今日已扫码") {
                  Toast.fail("今日已扫码");
                } else {
                  Toast.fail("验证失败");
                }

                this.loading = false;
              }
            );
          }
        }
      } else {
        Toast.fail("无效码");
      }
    }
  }
};
</script>
